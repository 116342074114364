import { useSpring, animated } from "react-spring"
import { ISbRichtext } from "storyblok-js-client"

import optimizeImage from "@src/utilities/optimize-image"
import urlFromStoryblokLinkBlok from "@src/utilities/url-from-storyblok-link-blok"

import Button from "@src/components/Button"
import WYSIWYGContent from "@src/components/WYSIWYGContent"

import classNames from "@src/utilities/class-names"

export interface SplitBlockCTAProps {
  content: {
    background_color: {
      color: string
    }
    button: any[]
    content: ISbRichtext
    container_width?: {
      containerWidth?: string
    }
    featured_image: {
      filename: string
    }
    flourish_image?: {
      filename: string
    }
    header_text: string
    image_on_left?: boolean
    preheader_text: string
    text_color: {
      color: string
    }
  }
}

export default function SplitBlockCTA({ content }: SplitBlockCTAProps) {
  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 50,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })

  return (
    <div
      className={
        content.container_width?.containerWidth
          ? `container-${content.container_width?.containerWidth}`
          : "container-laptop"
      }
      data-component="split-block-cta"
    >
      <div
        className={classNames(
          "mb-8 relative lg:flex lg:mb-16",
          content.image_on_left === true ? "lg:flex-row" : "lg:flex-row-reverse"
        )}
      >
        <div
          className="bg-center bg-cover bg-no-repeat mx-auto relative w-4/5 z-10 before:content-[''] before:block before:pt-[100%] lg:flex-[1_0_50%]"
          style={{
            backgroundImage: `url(${optimizeImage(
              content.featured_image.filename,
              1600
            )})`,
          }}
        />
        <div
          className="bg-white text-black -mt-8 pb-4 pt-16 px-8 relative text-center md:pt-24 md:pb-16 lg:flex lg:flex-[1_0_50%] lg:items-center lg:mt-0 lg:p-0 lg:items-center"
          style={{
            ...(content?.background_color?.color && {
              backgroundColor: content?.background_color?.color,
            }),
            ...(content?.text_color?.color && {
              color: content?.text_color?.color,
            }),
          }}
        >
          <div className="lg:pt-0 lg:px-8 lg:self-center">
            <h4 className="font-serif italic mb-2">{content.preheader_text}</h4>
            <h3 className="font-extrabold leading-normal uppercase text-2xl lg:leading-normal lg:text-4xl">
              {content.header_text}
            </h3>
            <WYSIWYGContent
              content={content}
              classNames={["prose prose-lg max-w-none w-full mb-4"]}
            />
            {content.button && content.button.length === 1 && (
              <Button
                url={urlFromStoryblokLinkBlok(content.button[0].button_url)}
                text={content.button[0].button_text}
                type={content.button[0].button_style}
              />
            )}
          </div>
        </div>
        {content?.flourish_image?.filename &&
          content.flourish_image.filename.length > 0 && (
            <animated.div
              className={classNames(
                "absolute bg-right bg-contain bg-no-repeat -right-4 top-0 w-48 z-[-1] before:content-[''] before:block before:pt-[100%]",
                content.image_on_left === true
                  ? "lg:bg-right lg:left-auto lg:right-full"
                  : "lg:bg-left lg:left-full lg:right-auto"
              )}
              style={{
                backgroundImage: `url(${optimizeImage(
                  content.flourish_image.filename,
                  300
                )})`,
                ...parallaxStyles,
              }}
            />
          )}
      </div>
    </div>
  )
}
