import { useSpring, animated } from "react-spring"
import StoryblokClient, { ISbRichtext } from "storyblok-js-client"

import optimizeImage from "@src/utilities/optimize-image"

import classNames from "@src/utilities/class-names"

export interface SplitBlockProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    content: ISbRichtext
    featured_image?: {
      filename?: string
    }
    flourish_image?: {
      filename?: string
    }
    image_on_left?: boolean
    headline?: string
    preheadline?: string
  }
}

export default function SplitBlock({ content }: SplitBlockProps) {
  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 50,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })
  return (
    <div
      className={`container-${
        content?.container_width?.containerWidth ?? "laptop"
      }`}
      data-component="split-block"
    >
      <div
        className={classNames(
          "mb-16 relative lg:flex",
          content?.image_on_left ? "lg:flex-row" : "lg:flex-row-reverse"
        )}
      >
        <div
          className="bg-center bg-cover mx-auto relative w-4/5 before:content-[''] before:block before:pt-[100%] lg:before:pt-0 lg:flex-[1_0_50%]"
          style={{
            backgroundImage: `url(${optimizeImage(
              content &&
                content.featured_image &&
                content.featured_image.filename,
              1600
            )})`,
          }}
        />
        <div className="pt-16 relative -mt-8 md:p-16 lg:flex lg:flex-[1_0_50%] lg:mt-0 lg:p-0 lg:items-center">
          <div
            className={classNames(
              "lg:py-16",
              content?.image_on_left ? "lg:pl-16" : "lg:pr-16"
            )}
          >
            {content?.preheadline && (
              <h4
                className="font-serif italic mb-2"
                dangerouslySetInnerHTML={{
                  __html: content.preheadline,
                }}
              />
            )}
            {content?.headline && (
              <h3
                className="font-bold mb-2 uppercase text-2xl lg:text-4xl"
                dangerouslySetInnerHTML={{
                  __html: content.headline,
                }}
              />
            )}
            {content?.content && (
              <div
                className="prose prose-lg"
                dangerouslySetInnerHTML={{
                  __html:
                    content &&
                    content.content &&
                    new StoryblokClient({}).richTextResolver.render(
                      content.content
                    ),
                }}
              />
            )}
          </div>
        </div>
        {content?.flourish_image?.filename &&
          content.flourish_image.filename.length > 0 && (
            <animated.div
              className={classNames(
                "bg-right bg-contain absolute before:content-[''] before:block before:pt-[100%] -right-4 top-0 w-48",
                content?.image_on_left
                  ? "lg:bg-right lg:left-auto lg:right-full"
                  : "lg:bg-left lg:left-full lg:right-auto"
              )}
              style={{
                backgroundImage: `url(${optimizeImage(
                  content.flourish_image.filename,
                  300
                )})`,
                ...parallaxStyles,
              }}
            />
          )}
      </div>
    </div>
  )
}
