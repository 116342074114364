import StoryblockClient, { ISbRichtext } from "storyblok-js-client"

import Button from "@src/components/Button"

import optimizeImage from "@src/utilities/optimize-image"
import getSiteUrl from "@src/utilities/get-site-url"

export interface IconBlockProps {
  content?: {
    background_color?: { color: string }
    button?: {
      button_url?: {
        cached_url?: string
        url?: string
      }
      button_text?: string
      button_style?: any
    }[]
    container_width?: {
      containerWidth?: string
    }
    preheadline?: string
    headline?: string
    postheadline?: string
    icon_blocks: {
      description?: ISbRichtext
      heading?: string
      icon?: {
        alt?: string
        filename?: string
      }
      intro?: string
    }[]
    text_color?: { color?: string }
    use_separator_for_icon_block_headlines?: boolean
  }
}

function getFirstButtonUrl(content: IconBlockProps["content"]): string | null {
  if (!content?.button || !content.button.length) {
    return null
  }
  if (content.button[0].button_url?.url) {
    return content.button[0].button_url.url
  }
  return getSiteUrl(content.button[0].button_url?.cached_url)
}

export default function IconBlock({ content }: IconBlockProps) {
  const firstButtonUrl = getFirstButtonUrl(content)

  return (
    <div
      className=" mb-8 p-8 text-center md:mb-16 lg:py-16 lg:px-0"
      data-component="icon-block"
      style={{
        ...(content?.background_color?.color && {
          backgroundColor: content.background_color.color,
        }),
        ...(content?.text_color?.color && { color: content.text_color.color }),
      }}
    >
      <div
        className={`container-${
          content?.container_width?.containerWidth ?? "laptop"
        }`}
      >
        {content?.preheadline && content.preheadline.length > 0 && (
          <h4 className="font-serif italic mb-1 text-lg tracking-wide md:mb-4">
            {content.preheadline}
          </h4>
        )}
        {content?.headline && content.headline.length > 0 && (
          <h2 className="text-2xl font-extrabold uppercase mb-4 md:text-4xl">
            {content.headline}
          </h2>
        )}
        {content?.postheadline && content.postheadline.length > 0 && (
          <h4 className="font-serif italic mb-1 text-lg tracking-wide md:mb-4">
            {content.postheadline}
          </h4>
        )}
        <div className="mt-8 lg:content-start lg:flex lg:justify-between lg:mt-16">
          {content?.icon_blocks.map((iconBlock, idx) => (
            <div
              className="mb-8 lg:flex-1 lg:mb-0 lg:px-4"
              key={`icon_block_${idx}`}
            >
              {iconBlock.intro && iconBlock.intro.length > 0 && (
                <h5 className="font-extrabold text-center uppercase">
                  <span
                    className="border-b-4 border-black"
                    style={{
                      ...(content?.text_color?.color && {
                        borderColor: content.text_color.color,
                      }),
                    }}
                  >
                    {iconBlock.intro}
                  </span>
                </h5>
              )}
              {iconBlock.icon && iconBlock.icon.filename && (
                <img
                  alt={iconBlock.icon.alt}
                  className="block mx-auto mb-4 max-w-[4rem] w-full lg:h-20 lg:w-auto lg:max-w-none"
                  src={optimizeImage(iconBlock.icon.filename, 600)}
                />
              )}
              {iconBlock.heading &&
                iconBlock.heading.length > 0 &&
                content?.use_separator_for_icon_block_headlines && (
                  <h4
                    className="font-extrabold text-lg uppercase relative mb-8 after:bg-black after:content-[''] after:block after:h-1 after:left-1/2 after:absolute after:top-full after:w-1/4 after:-translate-x-1/2 after:translate-y-3"
                    dangerouslySetInnerHTML={{ __html: iconBlock.heading }}
                  />
                )}
              {iconBlock.heading &&
                iconBlock.heading.length > 0 &&
                !content?.use_separator_for_icon_block_headlines && (
                  <h3
                    className="font-extrabold uppercase"
                    dangerouslySetInnerHTML={{ __html: iconBlock.heading }}
                  />
                )}
              {iconBlock.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: new StoryblockClient({}).richTextResolver.render(
                      iconBlock.description
                    ),
                  }}
                />
              )}
            </div>
          ))}
        </div>
        {content?.button &&
          content.button.length === 1 &&
          content.button[0].button_text &&
          content.button[0].button_text.length > 0 &&
          firstButtonUrl && (
            <div className="lg:mt-16">
              <Button
                text={content.button[0].button_text}
                type={content.button[0].button_style}
                url={firstButtonUrl}
              />
            </div>
          )}
      </div>
    </div>
  )
}
