"use client"

import Image from "next/image"

import { formatCurrency } from "@src/utilities/format-numbers"

interface BundlePotentialProfitProps {
  _editable?: string
  _uid?: string
  component?: string
  profit?: string
  text?: string
}
export default function BundlePotentialProfit({
  content,
}: {
  content: BundlePotentialProfitProps
}) {
  const savings = formatCurrency(
    content?.profit ? Number(content.profit) * 100 : 0,
    true
  )

  // let retailTotal: number = 0
  // let wholesaleTotal: number = 0
  // components.forEach((component) => {
  //   if (component.component !== "wholesale_product_grid") {
  //     return
  //   }
  //   const cards = component.product_cards ?? []
  //   cards.forEach((card: any) => {
  //     if (!card.product) {
  //       return
  //     }
  //     const minQty = card.min_quantity ? Number(card.min_quantity) : 1
  //     const priceRetail = card.product.priceRetail ?? 0
  //     const priceWholesale = card.product.priceWholesale ?? 0.5 * priceRetail
  //     retailTotal += minQty * priceRetail
  //     wholesaleTotal += minQty * priceWholesale
  //   })
  // })

  return (
    <div className="container-desktop mb-8 lg:mb-16">
      <div className="bg-slate-100 flex items-center justify-center p-4 space-x-2">
        <Image
          loading="eager"
          src="/images/money-mouth-face.png"
          width={40}
          height={40}
          alt="Potential profit"
        />
        <span className="text-lg lg:text-2xl font-serif italic">
          {content?.text || ""} {savings}+
        </span>
      </div>
    </div>
  )
}
