import React from "react"
import { BsPlus } from "react-icons/bs"
import StoryblokClient, { ISbRichtext } from "storyblok-js-client"

import optimizeImage from "@src/utilities/optimize-image"

interface IconSplitBlockProps {
  content: {
    container_width?: {
      containerWidth?: string
    }
    headline: string
    icon_blocks: {
      _uid: string
      content: ISbRichtext
      featured_image: {
        alt: string
        filename: string
      }
      icon: {
        filename: string
      }
      title: string
    }[]
  }
}
export default function IconSplitBlock({ content }: IconSplitBlockProps) {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(0)

  return (
    <div
      className={`container-${
        content.container_width?.containerWidth ?? "laptop"
      }`}
      data-component="icon-split-block"
    >
      <div className="border-b-2 border-black mb-4 mt-16">
        <h3 className="font-extrabold mb-2 text-xl uppercase">
          {content.headline}
        </h3>
      </div>
      <div className="flex justify-around items-center mb-8">
        {content.icon_blocks &&
          content.icon_blocks.length > 0 &&
          content.icon_blocks.map((iconBlock, idx) => (
            <button
              className="bg-transparent border-0 p-0 text-center"
              key={`button-${iconBlock._uid}`}
              onClick={() => setCurrentSlideIndex(idx)}
            >
              <img
                className="block mx-auto md:mb-2 w-12"
                src={iconBlock.icon.filename}
              />
              <span className="hidden font-sans font-bold uppercase md:block">
                {iconBlock.title}
              </span>
            </button>
          ))}
      </div>
      <div className="mb-16">
        {content.icon_blocks &&
          content.icon_blocks.length > 0 &&
          content.icon_blocks.map((iconBlock, idx) => (
            <div
              className="flex flex-col-reverse transition-opacity md:flex-row"
              data-icon-split-block-active={currentSlideIndex === idx}
              key={`content-${iconBlock._uid}`}
            >
              <div className="prose pl-4 md:pl-0 md:pr-8 md:prose-lg">
                <div className="relative">
                  <h4 className="uppercase">{iconBlock.title}</h4>
                  <div className="pr-1 absolute right-full top-0">
                    <BsPlus />
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new StoryblokClient({}).richTextResolver.render(
                      iconBlock.content
                    ),
                  }}
                />
              </div>
              <div
                className="bg-center bg-cover relative md:flex-[1_0_50%] md:before:content-[''] md:before:block md:before:pt-[100%]"
                style={{
                  backgroundImage: `url(${optimizeImage(
                    iconBlock.featured_image.filename,
                    1250
                  )})`,
                }}
              >
                <img
                  alt={iconBlock.featured_image.alt}
                  className="block w-full md:hidden"
                  src={optimizeImage(iconBlock.featured_image.filename, 600)}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
