import React from "react"
import StoryblokClient, { ISbRichtext } from "storyblok-js-client"

import classNamesUtility from "@src/utilities/class-names"

export interface WYSIWYGContentProps {
  classNames?: string[]
  content: {
    content: ISbRichtext
  }
}
export default function WYSIWYGContent({
  classNames: classNamesProvided = [
    "prose",
    "prose-xl",
    "mx-auto",
    "px-4",
    "mb-16",
  ],
  content,
}: WYSIWYGContentProps) {
  return (
    <div
      className={classNamesUtility(...classNamesProvided)}
      dangerouslySetInnerHTML={{
        __html: new StoryblokClient({}).richTextResolver.render(
          content.content
        ),
      }}
      data-component="wysiwyg-content"
    />
  )
}
